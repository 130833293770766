import React, { ReactElement, memo } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  centerContent: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

interface Props {
  className?: string
}

// TODO: Replace to use https://material-ui.com/components/backdrop/
const Loader: React.FC<Props> = memo(
  ({ className, ...props }): ReactElement => {
    const classes = useStyles()
    const loaderClassName = `${classes.centerContent} ${className || ''}`

    return (
      <Box className={loaderClassName} position="absolute">
        <CircularProgress color="primary" {...props} />
      </Box>
    )
  },
)

export { Loader }
