import firebase from 'firebase/compat/app'
import moment from 'moment-timezone'
import momentLegacy from 'moment'

import { timezone } from 'config/timezone'
import { Advertisement } from 'types'
import { splitChunks } from 'utils/arrays'

import {
  COLLECTION_ADVERTISEMENTS,
  COLLECTION_ADVERTISEMENTS_SEEN_BY_USERS,
  db,
} from './firebase'

moment.tz.setDefault(timezone)

async function filteredSeenAds(
  advertisements: Advertisement[],
  userKey: string,
): Promise<Advertisement[]> {
  const filteredAds: Advertisement[] = []

  await Promise.all(
    advertisements.map(async (advertisement) => {
      if (advertisement.key) {
        const querySnapshot = await db
          .collection(COLLECTION_ADVERTISEMENTS_SEEN_BY_USERS)
          .where('userKey', '==', userKey)
          .where(
            advertisement.key,
            '==',
            moment().hour(0).minutes(0).seconds(0).unix(),
          )
          .get()

        if (querySnapshot.empty) {
          filteredAds.push(advertisement)
        }
      }
    }),
  )

  return filteredAds
}

async function updateUserSeenAds(
  advertisements: Advertisement[],
  userKey: string,
): Promise<void> {
  // 1. Get the document reference.
  const docRef = db
    .collection(COLLECTION_ADVERTISEMENTS_SEEN_BY_USERS)
    .doc(`${userKey}-client`)

  // 2. Get ads keys
  const ads = {}

  advertisements.forEach((ad: Advertisement) => {
    if (ad.key) {
      ads[ad.key] = moment().hour(0).minutes(0).seconds(0).unix()
    }
  })

  return db.runTransaction(
    async (transaction: firebase.firestore.Transaction): Promise<void> => {
      const doc = await transaction.get(docRef)
      const item = doc.exists ? (doc.data() as Advertisement) : undefined
      const userAds = item || {}

      transaction.set(
        docRef,
        {
          userKey,
          ...userAds,
          ...ads,
        },
        { merge: true },
      )
    },
  )
}

async function findAdsBy({
  shopKeys,
}: {
  shopKeys?: string[]
}): Promise<Advertisement[]> {
  const map: { [key: string]: Advertisement } = {}
  const chunkedArray = shopKeys ? splitChunks(shopKeys, 10) : ['']
  const currentDate = moment().unix()
  const currentHour = Number(moment().format('HHmm'))

  for (const chunk of chunkedArray) {
    let queryBuilder: firebase.firestore.Query<firebase.firestore.DocumentData> = db.collection(
      COLLECTION_ADVERTISEMENTS,
    )

    if (currentDate) {
      queryBuilder = queryBuilder.where('toDate', '>=', currentDate)
    }

    if (shopKeys) {
      queryBuilder = queryBuilder.where('shopKeys', 'array-contains-any', chunk)
    }

    const querySnapshot = await queryBuilder
      .where('target', '==', 'client')
      .where('isActive', '==', true)
      .get()

    const queryDocuments = querySnapshot.docs
    queryDocuments.forEach((doc: firebase.firestore.DocumentData): void => {
      const item = doc.data() as Advertisement

      let shouldInsert = false
      if (
        currentDate &&
        item.fromDate < currentDate &&
        currentDate < item.toDate &&
        (item.days?.length || 0) > 0
      ) {
        const dayName = momentLegacy
          .unix(currentDate)
          .locale('en')
          .format('dddd')
          .toLowerCase()

        // @ts-expect-error
        shouldInsert = item.days.includes(dayName)
      } else if (
        // If there is no currentDate filter add them all or
        !currentDate ||
        // the date is between the range
        (item.fromDate < currentDate && currentDate < item.toDate)
      ) {
        shouldInsert = true
      }

      // Filter by hours if exist
      if (
        currentHour !== undefined &&
        shouldInsert &&
        item.toHour !== undefined &&
        item.fromHour !== undefined
      ) {
        if (!(item.fromHour <= currentHour && currentHour <= item.toHour)) {
          shouldInsert = false
        }
      }

      if (shouldInsert) {
        map[doc.id] = {
          ...item,
          key: doc.id,
        }
      }
    })
  }

  return Object.values(map).sort(
    (a, b) => (a.createdAt || 0) - (b.createdAt || 0),
  )
}

export const ApiServiceAdvertisements = {
  filteredSeenAds,
  findAdsBy,
  updateUserSeenAds,
}
