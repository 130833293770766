// @ts-nocheck
import Sentry from 'utils/sentry'

function setAppBadge(number: number): void {
  try {
    if (number <= 0) {
      clearAppBadge()

      return
    }

    if (navigator.setAppBadge) {
      navigator.setAppBadge(number)
    } else if (navigator.setExperimentalAppBadge) {
      navigator.setExperimentalAppBadge(number)
    } else if (window.ExperimentalBadge) {
      window.ExperimentalBadge.set(number)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

// Wrapper to support first and second origin trial
// See https://web.dev/badging-api/ for details.
function clearAppBadge(): void {
  try {
    if (navigator.clearAppBadge) {
      navigator.clearAppBadge()
    } else if (navigator.clearExperimentalAppBadge) {
      navigator.clearExperimentalAppBadge()
    } else if (window.ExperimentalBadge) {
      window.ExperimentalBadge.clear()
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const AppBadge = {
  setAppBadge,
}
