export function splitChunks<T>(array: T[] = [], size: number): T[][] {
  if (array.length === 0) {
    return []
  }

  const chunkedArray: T[][] = []
  let index = 0
  while (index < array.length) {
    const chunk = array.slice(index, size + index)
    chunkedArray.push(chunk)
    index += size
  }

  return chunkedArray
}
