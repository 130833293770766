import { Action, action } from 'easy-peasy'

import { Shop } from 'types'

interface ShopsState {
  shops: Shop[]
}

export interface ShopsModel extends ShopsState {
  // Actions
  resetStore: Action<ShopsModel, ShopsState>
  setShops: Action<ShopsModel, Shop[]>
}

const initialState: ShopsState = {
  shops: [],
}

const shopsModel: ShopsModel = {
  ...initialState,
  resetStore: action(
    (state: ShopsState): ShopsState => ({ ...state, ...initialState }),
  ),
  setShops: action(
    (state: ShopsState, shops: Shop[]): ShopsState => ({ ...state, shops }),
  ),
}

export { shopsModel }
