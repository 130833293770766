export const fontSizes = {
  body1: 16,
  body2: 14,
  button: 14,
  caption: 12,
  h1: 48,
  h2: 34,
  h3: 24,
  h4: 20,
  h5: 18,
  h6: 16,
  overline: 12,
  subtitle1: 18,
  subtitle2: 14,
}

export const fonts = {
  main: 'Twemoji Country Flags, Inter, sans-serif',
  title: 'Twemoji Country Flags, Plus Jakarta Sans, serif',
}
