import { Action, action } from 'easy-peasy'

import {
  GameConfiguration,
  GameEventTypes,
  GameType,
  Shop,
  Ticket,
  TicketBet,
  TicketBetMegaReventados,
  TicketBetReventados,
} from 'types'

export interface TicketModel extends Ticket {
  resetStore: Action<TicketModel>
  resetTicket: Action<TicketModel>
  setTicket: Action<TicketModel, Ticket | undefined>
  setTicketShop: Action<TicketModel, Shop | undefined>
  setTicketGameEventType: Action<TicketModel, GameEventTypes>
  setTicketGameType: Action<TicketModel, GameType | undefined>
  setTicketDate: Action<TicketModel, number>
  setTicketTimeSlot: Action<TicketModel, string>
  setTicketDateAndTime: Action<TicketModel, { date: number; timeSlot: string }>
  setGameConfiguration: Action<TicketModel, GameConfiguration>
  setBets: Action<TicketModel, TicketBet | undefined>
  setBetsMegaReventados: Action<
    TicketModel,
    TicketBetMegaReventados | undefined
  >
  setBetsReventados: Action<TicketModel, TicketBetReventados | undefined>
}

const initialState: Ticket = {
  bets: undefined,
  betsMegaReventados: undefined,
  betsReventados: undefined,
  date: 0,
  datePlayed: 0,
  gameEventType: 'tiempos',
  gameType: undefined,
  gameTypeKey: undefined,
  gameTypeName: undefined,
  isActive: false,
  isWinner: false,
  key: undefined,
  multiX: 'JG',
  numbers: [],
  reventadosBallColor: 'white',
  shop: undefined,
  shopKey: undefined,
  shopName: undefined,
  timeSlot: undefined,
  timesOfPayment: '',
  totalBet: 0,
  userId: '',
  userKey: undefined,
  userName: '',
}

const ticketModel: TicketModel = {
  ...initialState,
  resetStore: action(
    (state: Ticket): Ticket => ({ ...state, ...initialState }),
  ),
  resetTicket: action(
    (state: Ticket): Ticket => ({ ...state, ...initialState }),
  ),
  setBets: action(
    (state: Ticket, bets: TicketBet | undefined): Ticket => ({
      ...state,
      bets,
    }),
  ),
  setBetsMegaReventados: action(
    (
      state: Ticket,
      betsMegaReventados: TicketBetMegaReventados | undefined,
    ): Ticket => ({
      ...state,
      betsMegaReventados,
    }),
  ),
  setBetsReventados: action(
    (
      state: Ticket,
      betsReventados: TicketBetReventados | undefined,
    ): Ticket => ({
      ...state,
      betsReventados,
    }),
  ),
  setGameConfiguration: action(
    (state: Ticket, gameConfiguration: GameConfiguration): Ticket => ({
      ...state,
      gameConfiguration,
    }),
  ),
  setTicket: action(
    (state: Ticket, payload: Ticket | undefined): Ticket => ({
      ...state,
      ...payload,
    }),
  ),
  setTicketDate: action(
    (state: Ticket, date: number): Ticket => ({
      ...state,
      date,
    }),
  ),
  setTicketDateAndTime: action(
    (
      state: Ticket,
      { date, timeSlot }: { date: number; timeSlot: string },
    ): Ticket => ({
      ...state,
      date,
      timeSlot,
    }),
  ),
  setTicketGameEventType: action(
    (state: Ticket, gameEventType: GameEventTypes): Ticket => ({
      ...state,
      gameEventType,
    }),
  ),
  setTicketGameType: action(
    (state: Ticket, gameType?: GameType): Ticket => ({
      ...state,
      gameEventType: gameType ? gameType.eventType : 'tiempos',
      gameType,
    }),
  ),
  setTicketShop: action(
    (state: Ticket, shop: Shop | undefined): Ticket => ({ ...state, shop }),
  ),
  setTicketTimeSlot: action(
    (state: Ticket, timeSlot: string): Ticket => ({
      ...state,
      timeSlot,
    }),
  ),
}

export { ticketModel }
