import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import { ReactElement } from 'react'

import { StoreProvider } from 'easy-peasy'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'

import Sentry from 'utils/sentry'

import App from './app'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store, { persistor } from './store'

function Root(): ReactElement {
  return (
    <StoreProvider store={store}>
      <PersistGate
        loading={null}
        // @ts-ignore
        persistor={persistor}
      >
        <App />
      </PersistGate>
    </StoreProvider>
  )
}

// Initialize Sentry for Log Monitoring
Sentry.initSentry()

ReactDOM.render(<Root />, document.getElementById('root'))

serviceWorkerRegistration.register({
  onUpdate: async (registration: ServiceWorkerRegistration): Promise<void> => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    if (registration && registration.waiting) {
      await registration.unregister()
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      window.location.reload()
    }
  },
})

// if (navigator) {
//   ;(async () => {
//     try {
//       const subscriptions = navigator.serviceWorker
//         .getRegistrations()
//         .then((r) => {
//           return r.map((sw) => {
//             if (
//               !(sw.active && sw.active.scriptURL.includes('firebase-messaging'))
//             ) {
//               return Promise.resolve()
//             }

//             return sw.pushManager.getSubscription()
//           })
//         })

//       console.log('subscriptions', subscriptions)

//       const arr = await subscriptions
//       console.log('arr', arr)
//       // @ts-ignore
//       if (arr?.[0] && arr[0]) {
//         console.log('arr[0]', arr[0])
//         // @ts-ignore
//         const subs = arr[0]

//         if (subs?.unsubscribe) {
//           const
//           console.log(
//             subs.unsubscribe().then((result: any) => {
//               console.log(result)
//             }),
//           )
//         }
//       }
//     } catch (error) {
//       Sentry.captureException(error)
//     }
//   })()
// }
