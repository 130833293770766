import React, { ComponentType, ReactElement, lazy, memo } from 'react'

const PublicRoutes = lazy(
  (): Promise<{ default: ComponentType }> => import('./public-routes'),
)
// const PrivateRoutes = lazy(
//   (): Promise<{ default: ComponentType }> => import('./private-routes'),
// )

interface Props {
  isAuthenticated: boolean
}

const Routers: React.FC<Props> = memo(
  (_props: Props): ReactElement => <PublicRoutes />,
)

export { Routers }
