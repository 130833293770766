import { useEffect } from 'react'
import { toast } from 'react-toastify'

function showStableInternet() {
  toast.dismiss()
  toast.success('Conexión a internet restablecida', {
    autoClose: 10000,
    closeOnClick: true,
    hideProgressBar: false,
    pauseOnHover: false,
    position: 'bottom-center',
  })
}

function showErrorInternet() {
  toast.dismiss()
  toast.error('Verifique la conexión a internet', {
    autoClose: 10000,
    closeOnClick: true,
    hideProgressBar: false,
    pauseOnHover: false,
    position: 'bottom-center',
  })
}

function useInternetChecker(): void {
  useEffect(() => {
    window.addEventListener('online', showStableInternet)
    window.addEventListener('offline', showErrorInternet)

    return () => {
      window.removeEventListener('online', showStableInternet)
      window.removeEventListener('offline', showErrorInternet)
    }
  }, [])
}

export { useInternetChecker }
