import firebase from 'firebase/compat/app'
import moment from 'moment-timezone'

import { timezone } from 'config/timezone'
import { UserNotification } from 'types'
import { splitChunks } from 'utils/arrays'
import Sentry from 'utils/sentry'

import { COLLECTION_USER_NOTIFICATIONS, db } from './firebase'

moment.tz.setDefault(timezone)

async function getUnreadNotificationsNumber({
  userKey,
}: {
  userKey: string
}): Promise<number> {
  if (!userKey) {
    return 0
  }

  const query = await db
    .collection(COLLECTION_USER_NOTIFICATIONS)
    .where('userKey', '==', userKey)
    .where('isRead', '==', false)
    .get()

  return query.size
}

async function getNotifications({
  lastItem,
  limit = 30,
  userKey,
}: {
  lastItem?: firebase.firestore.QueryDocumentSnapshot
  limit?: number
  userKey: string
}): Promise<{
  data: UserNotification[]
  pagination?: { lastDocument: firebase.firestore.QueryDocumentSnapshot }
}> {
  let itemsQuery: firebase.firestore.Query

  if (lastItem) {
    itemsQuery = db
      .collection(COLLECTION_USER_NOTIFICATIONS)
      .where('userKey', '==', userKey)
      .orderBy('createdAt', 'desc')
      .startAfter(lastItem)
  } else {
    itemsQuery = db
      .collection(COLLECTION_USER_NOTIFICATIONS)
      .where('userKey', '==', userKey)
      .orderBy('createdAt', 'desc')
  }

  const querySnapshot = await itemsQuery.limit(limit).get()
  if (querySnapshot.empty) {
    return { data: [] }
  }

  const queryDocuments = querySnapshot.docs
  const data = queryDocuments.map(
    (doc: firebase.firestore.DocumentData): UserNotification => {
      const item = doc.data() as UserNotification

      return {
        ...item,
        key: doc.id,
      }
    },
  )
  const lastDocument = querySnapshot.docs[queryDocuments.length - 1]

  return {
    data,
    pagination: { lastDocument },
  }
}

async function updateReadNotifications(
  notifications: UserNotification[],
): Promise<void> {
  try {
    // 500 transactions is Firebase max limit per request.
    const chunkedArray = splitChunks(notifications, 500)

    for (const chunk of chunkedArray) {
      const batch = db.batch()

      chunk.forEach((notification) => {
        if (notification.key) {
          const ref = db
            .collection(COLLECTION_USER_NOTIFICATIONS)
            .doc(notification.key)

          batch.update(ref, { isRead: true, updatedAt: moment().unix() })
        }
      })

      await batch.commit()
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

// Subscriptions
function subscribeToUserNotifications(
  userKey: string,
  handleSnapshot: (number: number) => void,
): () => void {
  return db
    .collection(COLLECTION_USER_NOTIFICATIONS)
    .where('userKey', '==', userKey)
    .where('isRead', '==', false)
    .onSnapshot(
      (
        snapshot: firebase.firestore.QuerySnapshot<
          firebase.firestore.DocumentData
        >,
      ): void => {
        try {
          handleSnapshot(snapshot.size)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      (error) => {
        if (error) {
          Sentry.captureException(error)
        }
      },
    )
}

export const ApiServiceUserNotifications = {
  getNotifications,
  getUnreadNotificationsNumber,
  subscribeToUserNotifications,
  updateReadNotifications,
}
