import { Action, action } from 'easy-peasy'

import { UserNotification } from 'types'

interface UserNotificationState {
  notifications: UserNotification[]
  unreadNotifications: number
}

export interface UserNotificationModel extends UserNotificationState {
  // Actions
  resetStore: Action<UserNotificationModel, UserNotificationState>
  setNotifications: Action<UserNotificationModel, UserNotification[]>
  setUnreadNotifications: Action<UserNotificationModel, number>
}

const initialState: UserNotificationState = {
  notifications: [],
  unreadNotifications: 0,
}

const userNotificationModel: UserNotificationModel = {
  ...initialState,
  resetStore: action((state) => ({
    ...state,
    ...initialState,
  })),
  setNotifications: action((state, notifications) => ({
    ...state,
    notifications,
  })),
  setUnreadNotifications: action((state, unreadNotifications) => ({
    ...state,
    unreadNotifications,
  })),
}

export { userNotificationModel }
