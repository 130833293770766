import { Suspense, useEffect, useState } from 'react'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Actions, State } from 'easy-peasy'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import semverCompare from 'semver-compare'
import 'react-toastify/dist/ReactToastify.css'

import { Routers } from 'routers'
import { ApiServiceUserNotifications, ApiServiceUsers } from 'services'
import { firebaseAuth } from 'services/firebase'
import {
  ActionModel,
  StateModel,
  resetStores,
  useStoreActions,
  useStoreState,
} from 'store'
import { SessionModel, User, UserModel, UserNotificationModel } from 'types'
import { useCustomTheme } from 'utils/use-custom-theme'
import { Loader } from 'views/components/loader'
import Sentry from 'utils/sentry'
import { useInternetChecker } from 'utils/use-internet-checker'
import { AppBadge } from 'utils/app-badge'
import { APP_VERSION } from 'config/app-version'

const App = (): JSX.Element => {
  const { theme } = useCustomTheme()
  useInternetChecker()

  // Global state
  const {
    appVersion,
    key: userKey,
    userId,
    wallets,
    ...userRest
  } = useStoreState((state: StateModel): State<UserModel> => state.user)

  // Global Actions
  const { setUser } = useStoreActions(
    (actions: ActionModel): Actions<UserModel> => actions.user,
  )
  const { setUnreadNotifications } = useStoreActions(
    (state: ActionModel): Actions<UserNotificationModel> =>
      state.userNotifications,
  )
  const { setPromptHandler } = useStoreActions(
    (actions: ActionModel): Actions<SessionModel> => actions.session,
  )

  // Component state
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect((): void => {
    setIsAuthenticated(userId !== '')
  }, [userId])

  useEffect((): void => {
    async function updateAppVersion(): Promise<void> {
      try {
        if (
          userKey &&
          APP_VERSION !== undefined &&
          (!appVersion || semverCompare(APP_VERSION, appVersion) === 1)
        ) {
          await ApiServiceUsers.updateUser({
            appVersion: APP_VERSION,
            shopKeys: Object.keys(wallets || []),
          })

          Sentry.setUser({
            appVersion: APP_VERSION,
            id: userId,
            key: userKey,
            username: userRest.name,
          })
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    updateAppVersion()
  }, [appVersion, userId, userKey, userRest.name, wallets])

  useEffect((): any => {
    let unsubscribe: undefined | (() => void)

    try {
      if (!userKey) {
        if (unsubscribe) {
          unsubscribe()
        }

        return
      }

      unsubscribe = ApiServiceUserNotifications.subscribeToUserNotifications(
        userKey,
        async (number = 0): Promise<void> => {
          setUnreadNotifications(number)

          AppBadge.setAppBadge(number)
        },
      )
    } catch (error) {
      Sentry.captureException(error)
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [setUnreadNotifications, userKey])

  useEffect((): any => {
    let unsubscribe: undefined | (() => void)

    try {
      if (!userKey) {
        if (unsubscribe) {
          unsubscribe()
        }

        return
      }

      unsubscribe = ApiServiceUsers.subscribeToUser(
        userKey,
        async (user?: User): Promise<void> => {
          if (!user || !user.isActive || user.isDeleted) {
            resetStores()
          } else {
            setUser(user)
          }
        },
      )
    } catch (error) {
      Sentry.captureException(error)
    }

    return (): void => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [setUser, userKey])

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        resetStores()
      }
    })

    return (): void => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    function eventHandler(e: any) {
      try {
        // Stash the event so it can be triggered later.
        setPromptHandler(e)
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    if (!window.matchMedia('(display-mode: standalone)').matches) {
      window.addEventListener('beforeinstallprompt', eventHandler)
    }
  }, [setPromptHandler])

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loader />}>
          <Routers isAuthenticated={isAuthenticated} />
        </Suspense>

        <ToastContainer limit={1} newestOnTop />
      </MuiThemeProvider>
    </BrowserRouter>
  )
}

export default App
